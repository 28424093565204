import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import styles from "./style.module.css";
import crossImg from "../../../assets/crossImg.svg";
import {
  fetchPartnerAdData,
  deletePartnerAdData,
  updatePartnerAdData,
} from "../../../Redux/Advertisement/Partner/PartnerAdReducer";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { RxCrossCircled } from "react-icons/rx";

const DeleteModel = ({
  el,
  type,
  search,
  page,
  partnerAd,
  adType,
  releaseStartDate,
  releaseEndDate,
  createdStartDate,
  createdEndDate,
}) => {
  console.log("el", el);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const partnerId = JSON.parse(localStorage.getItem("userId"));
  const handleYes = () => {
    const data = {
      adId: el._id,
      partnerId: el.partnerId._id,
      status:
        el?.status === "Approved"
          ? "Cancelled"
          : el?.status === "Requested" && "Deleted",
    };
    if (partnerAd) {
      dispatch(updatePartnerAdData(data)).then(() => {
        dispatch(
          fetchPartnerAdData(
            (type = "Overview"),
            (search = ""),
            (page = "1"),
            (adType = ""),
            (releaseStartDate = ""),
            (releaseEndDate = ""),
            (createdStartDate = ""),
            (createdEndDate = ""),
            partnerId
          )
        ).then(() => {
          onClose();
          message.success("Advertisement successfully delete");
        });
      });
    }
  };
  useEffect(() => {
    console.log("el", el);
    if (el.partnerId && el.partnerId._id) {
      console.log("elid", el.partnerId._id || el._id);
    } else {
      console.log("partnerId or _id is undefined or null");
    }
  }, []);
  return (
    <>
      <p onClick={onOpen} className={`flex gap-2 ${styles.blackBtn31}`}>
        <RxCrossCircled fontSize={20} /> Cancel
      </p>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody></ModalBody>
          <div className={styles.deleteModeldiv}>
            <img src={crossImg} alt="" />

            <p>
              Are your sure want to cancel this
              <br />
              Advertisement?
            </p>
          </div>

          <ModalFooter>
            <div className={styles.modelFooter}>
              <Button variant="solid" color="black" mr={3} onClick={handleYes}>
                Yes
              </Button>

              <Button variant="outline" color="black" onClick={onClose}>
                No
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteModel;
