import React, { useEffect, useState } from "react";
import styles from "../style.module.css";
import { Avatar } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../../Firebase_config";
import { formatCustomDate } from "../../../utils/utils";
import { Pagination } from "antd";
const Outbox = ({
  outboxArray,
  total,
  page,
  setPage,
  setPageSize,
  pageSize,
}) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    console.log("page", page);
    setCurrentPage(page);
    setPage(page);
  };
  const handlePageSizeChange = (current, size) => {
    console.log("page", current, size);
    setPageSize(size);
  };

  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
  });

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("userData"));
    if (storedUser) {
      setUser({
        name: storedUser.fullName + " " + storedUser.lastName,
      });
    }
  }, []);

  async function updateMessageSeen(outboxId, chatId) {
    const outboxChatUserRef = collection(db, "outbox", outboxId, "chatUser");

    try {
      const querySnapshot = await getDocs(outboxChatUserRef);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.id === chatId) {
          const chatDocRef = doc.ref;
          updateDoc(chatDocRef, {
            sender: true,
          })
            .then(() => {
              console.log("Message updated to msgSeen: true");
            })
            .catch((error) => {
              console.error("Error updating message:", error);
            });
        }
      });
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  }
  //   old function
  //   function sortMessages(messages) {
  //     const unseenMessages = messages.filter((message) => !message.receiver);
  //     const seenMessages = messages.filter((message) => message.receiver);

  //     return [...unseenMessages, ...seenMessages];
  //   }
  function sortMessages(messages) {
    return messages?.sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );
  }
  if (outboxArray?.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        No data available
      </div>
    );
  }
  console.log("outboxArray", outboxArray);
  return (
    <div className={styles.tableDiv}>
      <table
        className={`flex auto border-2 border-gray-200 overflow-auto ${styles.table}`}
      >
        <div
          className={`w-full ${styles.tableWrap} overflow-auto md:max-h-[37vw]`}
        >
          <tr className=" w-[160%]  md:w-full flex relative z-10 items-center justify-center md:justify-start">
            <th className="w-[27%] md:w-[20%]">From</th>
            <th className="w-[27%] md:w-[20%]">Subject</th>
            <th className="w-[27%] md:w-[20%]">Message Body</th>
            <th className="w-[27%] md:w-[20%]">Date & Time</th>
          </tr>

          {sortMessages(outboxArray)?.map((el, i) => {
            return (
              <tr
                key={i}
                onClick={() => {
                  updateMessageSeen(el.from, el.id).then(() => {
                    navigate(
                      `/Messages/Chat/${el.to}?inbox=false&created_by_type=${el.created_by_type}&chatId=${el.id}`
                    );
                  });
                }}
                className={`h-[60px] md:h-auto min-h-[60px] cursor-pointer flex w-[160%] md:w-full  hover:bg-gray-100 transition-all delay-75 ease-out border-t border-gray-200 items-center`}
                // ${
                //   !el.receiver && styles.unseen
                // }
              >
                {/* <td className="w-[27%] md:w-[22%]">{i + 1}</td> */}
                {/* <td className="w-[27%] md:w-[20%] text-center relative mt-2">
                  <div className="w-[45px] h-[45px] rounded-full  bg-[#B9DEF1] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <p className="text-2xl text-[#2C6E8F] font-semibold absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                      N
                    </p>
                  </div>
                </td> */}
                <td className="w-[27%] md:w-[20%] mt-2"> {el?.sender_name}</td>

                <td className="w-[27%] md:w-[20%] mt-2 truncate">
                  {" "}
                  {el?.subject}
                </td>
                <td className="w-[27%] md:w-[20%] mt-2 truncate">{el?.Msg}</td>
                <td className="w-[27%] md:w-[20%] mt-2">
                  {formatCustomDate(el?.timestamp)}
                </td>
              </tr>
            );
          })}
        </div>
      </table>
      {total > 10 && (
        <div className={styles.pagination}>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={total}
            onChange={handlePageChange}
            onShowSizeChange={(current, size) =>
              handlePageSizeChange(current, size)
            }
            // showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default Outbox;
