import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { RxCross1 } from "react-icons/rx";
import attach from "../../../assets/attach.svg";
import { ImTelegram } from "react-icons/im";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  updateDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../Firebase_config";
import { fileRequest } from "../../../requestMethod";
import { useLocation, useParams } from "react-router-dom";
import Messages from "./Components/Messages";
import { Avatar } from "@chakra-ui/react";
import { message } from "antd";
import { userRequest } from "../../../requestMethod";

const Chat = () => {
  const params = useParams();
  const location = useLocation();
  const [document, setDocument] = useState("");

  const queryParams = new URLSearchParams(location.search);
  const inboxQuery = queryParams.get("inbox");
  const created_by_type = queryParams.get("created_by_type");
  const chatId = queryParams.get("chatId");
  const [partner, setPartner] = useState({});
  const [msg, setMsg] = React.useState("");
  const [subject, setSubject] = useState("");
  const [inbox, setInbox] = useState([]);
  const [chatInbox, setChatInbox] = useState([]);

  const id = JSON.parse(localStorage.getItem("userId"));

  const chatInboxId = chatInbox.find((chat) => chat.id === chatId);

  // Here’s a clearer version of your text:

  // In this function, I check if the inbox query is true and if the chat was initiated by the partner. If so, the query will support the inbox for the admin and the outbox for the partner. In the other case, if the inbox query is false, the query will support the inbox for the partner and the outbox for the admin.

  const fetchMessagesInbox = async () => {
    try {
      let userInfoCollectionRef = "";
      if (inboxQuery === "true") {
        if (created_by_type === "partner") {
          userInfoCollectionRef = collection(
            db,
            "Chats",
            id,
            "chatUser",
            params.id,
            chatId
          );
        } else {
          userInfoCollectionRef = collection(
            db,
            "Chats",
            params.id,
            "chatUser",
            id,
            chatId
          );
        }
      } else {
        if (created_by_type === "partner") {
          userInfoCollectionRef = collection(
            db,
            "Chats",
            id,
            "chatUser",
            params.id,
            chatId
          );
        } else {
          userInfoCollectionRef = collection(
            db,
            "Chats",
            params.id,
            "chatUser",
            id,
            chatId
          );
        }
      }
      inboxQuery === "true"
        ? console.log(
            "Chats",
            params.id,
            "chatUser",
            id,
            chatId,
            created_by_type
          )
        : console.log(
            "Chats",
            id,
            "chatUser",
            params.id,
            chatId,
            created_by_type
          );

      const unsubscribe = onSnapshot(userInfoCollectionRef, (snapshot) => {
        // deleteNewChatBetween(router.query._id, props.user._id);
        const messages = [];
        snapshot.forEach((doc) => {
          messages.push(doc.data());
        });
        updateOnSnapshotMessageSeen(id, chatId);
        const sortedMessages = messages.sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
        setInbox(sortedMessages);
        if (sortedMessages.length > 0) {
          const lastMessage = sortedMessages[sortedMessages.length - 1];
        }
      });

      // Return the unsubscribe function to detach the listener when needed
      return unsubscribe;
    } catch (error) {
      console.error("Error fetching messages: ", error);
    }
  };

  useEffect(() => {
    const messageInfoCollectionRef =
      inboxQuery === "true"
        ? collection(db, "inbox", id, "chatUser")
        : collection(db, "outbox", id, "chatUser");
    const fetchInbox = async () => {
      try {
        const unsubscribe = onSnapshot(messageInfoCollectionRef, (snapshot) => {
          const messages = [];
          snapshot.forEach((doc) => {
            messages.push(doc.data());
          });
          setChatInbox(messages);
        });

        // Return the unsubscribe function to detach the listener when needed
        return unsubscribe;
      } catch (error) {
        console.error("Error fetching messages: ", error);
      }
    };
    fetchInbox();
  }, [id, inboxQuery]);

  useEffect(() => {
    fetchMessagesInbox();
  }, []);

  useEffect(() => {
    const getData = () => {
      userRequest.get(`/partner/business/getBusiness?_id=${id}`).then((res) => {
        setPartner(res.data.data);
      });
    };
    getData();
  }, [id]);

  async function updateSendMessageSeen(outboxId, chatId) {
    const path = inboxQuery !== "true" ? "inbox" : "outbox";
    const value =
      inboxQuery === "true"
        ? {
            sender: false,
          }
        : {
            receiver: false,
          };
    const outboxChatUserRef = collection(db, path, outboxId, "chatUser");

    try {
      const querySnapshot = await getDocs(outboxChatUserRef);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.id === chatId) {
          const chatDocRef = doc.ref;

          updateDoc(chatDocRef, value)
            .then(() => {
              console.log("Message updated to msgSeen: true");
            })
            .catch((error) => {
              console.error("Error updating message:", error);
            });
        }
      });
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  }

  function getFileTypeAndLink(url) {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    const filenameParts = filename.split(".");

    if (filenameParts.length > 1) {
      const fileExtension = filenameParts.pop().toLowerCase();

      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
      if (imageExtensions.includes(fileExtension)) {
        return url; // Return the original link if it's an image
      } else if (fileExtension === "pdf") {
        return "https://play-lh.googleusercontent.com/9XKD5S7rwQ6FiPXSyp9SzLXfIue88ntf9sJ9K250IuHTL7pmn2-ZB0sngAX4A2Bw4w";
      } else if (fileExtension === "docx") {
        return "https://play-lh.googleusercontent.com/gFABK_HVIVlHT1MhKw7q8YlQkdsJrdev7xtyHmbQHLiT9cjLUPSl4bTe0CsqMzI-69Y";
      } else if (fileExtension === "xlsx") {
        return "https://play-lh.googleusercontent.com/64kcVwFiko4_91zc2zkEQEw01_Ua1H2brLsinnsbNFeKyYLUprM3VJo5mYdcKtRLWCA";
      } else if (fileExtension === "csv") {
        return "https://thumbs.dreamstime.com/b/csv-file-icon-flat-design-document-download-css-button-vector-image-graphic-176040866.jpg";
      } else {
        return "https://static.vecteezy.com/system/resources/previews/006/692/271/original/document-icon-template-black-color-editable-document-icon-symbol-flat-illustration-for-graphic-and-web-design-free-vector.jpg"; // Return the provided link for non-image files
      }
    } else {
      return "https://static.vecteezy.com/system/resources/previews/006/692/271/original/document-icon-template-black-color-editable-document-icon-symbol-flat-illustration-for-graphic-and-web-design-free-vector.jpg"; // Return the provided link if there's no extension
    }
  }
  const upload = async (e) => {
    const formData = new FormData();
    const image = e.target.files[0]; // Get the first selected file from the array

    if (image) {
      formData.append("file", image);

      // Append the selected file to the FormData

      fileRequest
        .post("/api/util/uploadFile", formData)
        .then((res) => {
          console.log("res:", res.data.url);
          setDocument(res.data.url);
          message.success("File uploaded successfully");
        })
        .catch((err) => {
          console.log(err);
          message.error("File upload failed");
        });
    }
  };
  console.log("inboxDocId", chatInboxId?.inboxDocId);
  // const getOutboxId =
  const timestamp = new Date().toISOString();

  // I refactored your sendMessage function to maintain the outbox and inbox messages. I also added a check to see if the chat was initiated by the admin, and verified the parameter ID in the inbox or outbox.

  const sendMessage = async () => {
    try {
      let userInfoCollectionRef = "";
      if (inboxQuery === "true") {
        if (created_by_type === "partner") {
          userInfoCollectionRef = `Chats/${id}/chatUser/${params.id}/${chatId}`;
        } else {
          userInfoCollectionRef = `Chats/${params.id}/chatUser/${id}/${chatId}`;
        }
      } else {
        if (created_by_type === "partner") {
          userInfoCollectionRef = `Chats/${id}/chatUser/${params.id}/${chatId}`;
        } else {
          userInfoCollectionRef = `Chats/${params.id}/chatUser/${id}/${chatId}`;
        }
      }

      if (inboxQuery !== "true") {
        // console.log("inbox", msg);
        // console.log("document", document);
        await addDoc(collection(db, userInfoCollectionRef), {
          Msg: msg,
          document: document ? document : false,
          subject,
          from: id,
          to: params.id,
          msgSeen: false,
          timestamp,
        });
      } else {
        // console.log("inbox", msg);
        // console.log("document", document);
        await addDoc(collection(db, userInfoCollectionRef), {
          Msg: msg,
          document: document ? document : false,

          subject,
          from: id,
          to: params.id,
          msgSeen: false,
          timestamp,
        });
      }

      if (inboxQuery === "true") {
        let outboxDocRef0 = "";
        let inboxDocRef0 = "";

        const inboxDocRef = doc(
          db,
          `inbox/${params?.id}/chatUser`,
          chatInboxId?.inboxDocId
        );
        const inboxDocSnap = await getDoc(inboxDocRef);

        const inboxMessageData = {
          id: chatId,
          to: params?.id,
          Msg: msg,
          sender_name: partner?.name,
          receiver_name: "Admin",
          document: document ? document : false,
          subject: inbox[0]?.subject,
          from: id,
          sender: true,
          receiver: true,
          timestamp,
          created_by_type: inbox[0]?.created_by_type,
          inboxDocId: chatInboxId?.inboxDocId,
          outboxDocId: chatInboxId?.outboxDocId,
        };

        if (inboxDocSnap.exists()) {
          // Update the existing inbox document
          await updateDoc(inboxDocRef, inboxMessageData);
          console.log("Inbox document updated successfully");
        } else {
          // Create a new inbox document
          inboxDocRef0 = await addDoc(
            collection(db, `inbox/${params?.id}/chatUser`),
            inboxMessageData
          );
          console.log("New inbox document created successfully");
        }

        // Handle the outbox document
        const outboxDocRef = doc(
          db,
          `outbox/${id}/chatUser`,
          chatInboxId?.outboxDocId
        );
        const outboxDocSnap = await getDoc(outboxDocRef);

        const outboxMessageData = {
          id: chatId,
          sender_name: partner?.name,
          receiver_name: "Admin",
          to: params?.id,
          Msg: msg,
          document: document ? document : false,
          subject: inbox[0]?.subject,
          from: id,
          sender: true,
          receiver: false,
          timestamp,
          created_by_type: inbox[0]?.created_by_type,
          inboxDocId: chatInboxId?.inboxDocId,
          outboxDocId: chatInboxId?.outboxDocId,
        };

        if (outboxDocSnap.exists()) {
          // Update the existing outbox document
          await updateDoc(outboxDocRef, outboxMessageData);
          console.log("Outbox document updated successfully");
        } else {
          // Create a new outbox document
          outboxDocRef0 = await addDoc(
            collection(db, `outbox/${id}/chatUser`),
            outboxMessageData
          );

          console.log("New outbox document created successfully");
        }
      } else {
        await updateDoc(
          doc(
            db,
            `inbox/${params.id}/chatUser`,
            // chatInboxId?.outboxDocId
            //   ? chatInboxId?.outboxDocId
            //   :
            chatInboxId?.inboxDocId
          ),
          {
            // to: inboxQuery === "true" ? id : params?.id,
            // from: inboxQuery === "true" ? params?.id : id,
            Msg: msg,
            timestamp,
          }
        );
        await updateDoc(
          doc(
            db,
            `outbox/${id}/chatUser`,
            chatInboxId?.outboxDocId
            // ? chatInboxId?.outboxDocId
            // : chatInboxId?.inboxDocId
          ),
          {
            // to: inboxQuery === "true" ? id : params?.id,
            // from: inboxQuery === "true" ? params?.id : id,
            Msg: msg,
            timestamp,
          }
        );
      }

      setDocument("");
      setMsg("");
      updateSendMessageSeen(params.id, chatId);
    } catch (error) {
      console.error("Error adding new document: ", error);
    }
  };

  async function updateOnSnapshotMessageSeen(outboxId, chatId) {
    const path = inboxQuery === "true" ? "inbox" : "outbox";
    const value =
      inboxQuery !== "true"
        ? {
            sender: true,
          }
        : {
            receiver: true,
          };
    const outboxChatUserRef = collection(db, path, outboxId, "chatUser");

    try {
      const querySnapshot = await getDocs(outboxChatUserRef);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.id === chatId) {
          const chatDocRef = doc.ref;

          updateDoc(chatDocRef, value)
            .then(() => {
              console.log("Message updated to msgSeen: true");
            })
            .catch((error) => {
              console.error("Error updating message:", error);
            });
        }
      });
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setMsg(msg + "\n");
    } else if (e.key === "Enter") {
      e.preventDefault();
      // Call your function here when Enter is pressed without Shift
      sendMessage();
    }
  };

  return (
    <div className={`relative ${styles.MainContainer}`}>
      <div className={styles.headDiv}></div>
      <div className={styles.topDiv}>
        <p className="font-bold text-lg md:text-base">
          <a
            href="/Messages"
            className="cursor-pointer text-gray-500 hover:text-gray-800"
          >
            All Messages
          </a>{" "}
          <span className="mx-2">&gt; </span>
          <span>Message Detail</span>
        </p>
        <div className={styles.selectDiv}>
          <Avatar
            name={
              inboxQuery === "true"
                ? inbox[0]?.sender_name
                : inbox[0]?.receiver_name
            }
            bg="blue.300"
            size="lg"
          />
        </div>
        <div className="flex gap-3 items-center border-b pb-3 border-gray-300">
          <p className="font-bold text-lg">Send to:</p>
          <p className="text-gray-500 mt-0.5">NETME Support</p>
        </div>
        <div className={styles.subjectDiv}>
          <p className="text-lg text-gray-700">Subject</p>
          <p className="text-2xl text-gray-700 font-bold">
            {inbox[0]?.subject}
          </p>
        </div>
      </div>
      <div className={styles.chatDiv}>
        <Messages messages={inbox} />
      </div>
      <div
        className={`md:absolute md:bottom-10 md:w-[94%] ${styles.sendMsgDiv}`}
      >
        {document && (
          <span className={styles.previewDiv}>
            <RxCross1
              className={styles.prevCross}
              onClick={() => setDocument("")}
            />

            <img src={getFileTypeAndLink(document)} alt="" />
          </span>
        )}
        <textarea
          type="text"
          name=""
          id=""
          rows={3}
          onChange={(e) => setMsg(e.target.value)}
          value={msg}
          onKeyDown={handleKeyDown}
          placeholder="How can we help you"
          className="pt-3"
        />
        <div>
          <label htmlFor="main">
            <img src={attach} alt="" />
          </label>
          <input
            type="file"
            id="main"
            onChange={(e) => upload(e)}
            style={{ display: "none" }}
          />

          <ImTelegram
            fontSize={35}
            onClick={() => sendMessage()}
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default Chat;
