import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { userRequest } from "../../../requestMethod";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
function normalizeTime(timeString) {
  const parts = timeString.split(":");
  if (parts.length === 2) {
    return `${parts[0]}:${parts[1]}:00`; // Add seconds if missing
  } else {
    return timeString; // Already in HH:MM:SS format
  }
}
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function AdChart() {
  const [data, setData] = useState({
    internalClickStats: [],
    internalImpressionStats: [],
    invitesStats: [],
  });

  const params = useParams();

  useEffect(() => {
    const fetchSingleAd = async () => {
      try {
        const response = await userRequest.get(
          `/partner/ads/getAdvtertisementStats?_id=${params.id}&type=INTERNAL`
        );
        setData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingleAd();
  }, [params.id]);

  // Generate time labels from 12:00 AM to 11:00 PM with hourly intervals
  const labels = [];
  for (let i = 0; i < 24; i++) {
    const currentTime = new Date();
    currentTime.setHours(i, 0, 0); // Set minutes to 0 for hourly intervals
    const formattedTime = currentTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    labels.push(formattedTime);
  }
  console.log("I");
  // Function to transform data into time-based format
  const transformData = (stats) => {
    const timeData = new Array(24).fill(0); // Array for 24 hours

    stats.forEach((item) => {
      const normalizedTime = normalizeTime(item.time);
      const [hour, minute, second] = normalizedTime.split(":").map(Number);
      if (hour !== undefined && hour >= 0) {
        timeData[hour] += item.count;
      } else {
        console.error("Invalid time format:", item.time); // Log invalid formats
      }
    });

    return timeData;
  };
  const clicksData = {
    label: `Clicks(${data?.internalClickStats.length})`,
    data: transformData(data?.internalClickStats),
    borderColor: "#333333",
    backgroundColor: "#333333",
    fill: false,
    tension: 0.1,
  };

  const impressionsData = {
    label: `Impressions(${data?.internalImpressionStats.length})`,
    data: transformData(data?.internalImpressionStats),
    borderColor: "#8CC9E9",
    backgroundColor: "#8CC9E9",
    fill: false,
    tension: 0.1,
  };

  const invitesData = {
    label: `Invites(${data?.invitesStats.length})`,
    data: transformData(data?.invitesStats),
    borderColor: "#8CE9DB",
    backgroundColor: "#8CE9DB",
    fill: false,
    tension: 0.1,
  };

  const lineData = {
    labels,
    datasets: [impressionsData, clicksData, invitesData],
  };
  const options = {
    responsive: true,
    spanGaps: true,
    scale: {
      ticks: {
        precision: 0,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: false,
      },
    },
  };

  // const [dimensions, setDimensions] = useState({
  //   width: window.innerWidth > 600 ? 1000 : 300,
  //   height: 280,
  // });

  // useEffect(() => {
  //   const handleResize = () => {
  //     setDimensions({
  //       width: window.innerWidth > 600 ? 1000 : window.innerWidth,
  //       height: 280,
  //     });
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const customWidth = 1000;
  const customHeight = 280;

  // Check for empty data
  const hasData =
    clicksData.data.some((value) => value > 0) ||
    impressionsData.data.some((value) => value > 0) ||
    invitesData.data.some((value) => value > 0);

  return (
    <Line
      options={options}
      data={lineData}
      width={customWidth}
      height={customHeight}
    />
  );
}
