import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";

const animatedComponents = makeAnimated();

export default function SelectCities({ form, setForm }) {
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [searchResults, setSearchResults] = useState([]); // State for search results

  const fetchCities = async (searchTerm) => {
    if (!searchTerm || searchTerm.length === 0) {
      setSearchResults([]);
      return;
    }

    setIsLoading(true); // Show loading indicator
    try {
      const apiUrl = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        searchTerm
      )}&format=jsonv2&addressdetails=1`;
      const response = await axios.get(apiUrl);
      const citiesData = response?.data;
      if (!!citiesData.length > 0) {
        setSearchResults(citiesData);
      } else {
        console.log(`No data found for the city ${searchTerm}`);
      }
    } catch (error) {
      console.error("Error fetching data from OpenStreetMap API", error);
    } finally {
      setIsLoading(false); // Hide loading indicator
    }
  };

  useEffect(() => {
    fetchCities(form.city); // Call fetchCities on initial render to handle pre-selected options

    return () => {
      // Cleanup function to avoid unnecessary API calls on unmount
      setSearchResults([]);
    };
  }, [form.city]); // Re-run useEffect when form.city changes

  const handleSearch = (inputValue) => {
    fetchCities(inputValue);
  };

  const handleSelect = (selectedOptions) => {
    if (!selectedOptions) return;

    // Update form with selected cities
    // const selectedCities = selectedOptions.map((option) => option.value);
    let selectedCities = [];
    console.log("selectedOptions", selectedOptions);
    selectedCities.push(selectedOptions.value);
    // console.log("selectedCities", selectedCities);
    setForm((prevForm) => ({
      ...prevForm,
      cities: selectedCities,
      city: "", // Clear input field after selection
    }));

    // if (selectedCities.length > 0) {
    //   setChooseCity(selectedCities[selectedCities.length - 1]);
    // } else {
    //   setChooseCity("");
    // }
  };
  console.log("searchResults", searchResults);

  // const options = searchResults?.map((city) => {
  //   console.log("city", city);
  //   return {
  //     value: city?.address?.city,
  //     label: city?.display_name,
  //   };
  // });
  const options = searchResults
    ?.filter((city) => city?.address?.city) // Filter out entries without a city
    .map((city) => ({
      value: city.address.city,
      label: city.display_name,
    }));

  // const options = [
  //   { value: "All", label: "All" }, // Add "All" option
  //   ...searchResults
  //     ?.filter((city) => city?.address?.city) // Filter out entries without a city
  //     .map((city) => {
  //       console.log("city", city);
  //       return {
  //         value: city?.address?.city,
  //         label: city?.display_name,
  //       };
  //     }),
  // ];

  // Convert form.cities into the format react-select expects for the value prop
  const selectedCities = form.cities.map((city) => ({
    value: city,
    label: city,
  }));
  console.log("options", options);
  return (
    <Select
      isLoading={isLoading} // Show loading indicator while fetching results
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? "" : "",
          backgroundColor: "#ECF1F4",
          borderRadius: "16px",
          boxSizing: "10px",
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: "none",
        }),
      }}
      placeholder="City"
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti={false} // Set to false for single city selection
      value={selectedCities} // Set the initial selected value
      onChange={handleSelect}
      onInputChange={handleSearch}
      options={options}
    />
  );
}
