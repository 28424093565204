import React from "react";
import styles from "./../style.module.css";

const ReasonIcon = () => {
  return (
    <div
      className={`${styles.blackBtn311} hover:bg-[#8CC9E9] group hover:text-white py-2 w-28`}
    >
      <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.76"
          d="M17.5065 5.55291C16.6979 3.93422 15.4572 2.5708 13.9217 1.61351C12.3863 0.656233 10.6159 0.142411 8.80653 0.128906C6.99709 0.14273 5.22674 0.656794 3.69131 1.61423C2.15587 2.57167 0.915201 3.93517 0.106527 5.55391C0.0364918 5.69233 0 5.84528 0 6.00041C0 6.15553 0.0364918 6.30849 0.106527 6.44691C0.915157 8.0656 2.15585 9.42902 3.69131 10.3863C5.22678 11.3436 6.99715 11.8574 8.80653 11.8709C10.616 11.8571 12.3863 11.343 13.9217 10.3856C15.4572 9.42814 16.6979 8.06464 17.5065 6.44591C17.5766 6.30749 17.6131 6.15453 17.6131 5.99941C17.6131 5.84428 17.5766 5.69133 17.5065 5.55291ZM8.80653 10.4019C7.93629 10.4019 7.08559 10.1439 6.36202 9.66037C5.63844 9.17689 5.07448 8.48971 4.74146 7.68571C4.40843 6.88172 4.3213 5.99703 4.49107 5.14351C4.66085 4.28999 5.07991 3.50599 5.69526 2.89064C6.31061 2.27529 7.09461 1.85623 7.94813 1.68645C8.80165 1.51668 9.68634 1.60381 10.4903 1.93684C11.2943 2.26986 11.9815 2.83382 12.465 3.5574C12.9485 4.28097 13.2065 5.13167 13.2065 6.00191C13.2067 6.57981 13.0929 7.15207 12.8719 7.68602C12.6508 8.21997 12.3267 8.70514 11.9182 9.11382C11.5096 9.52251 11.0245 9.8467 10.4906 10.0679C9.95667 10.2891 9.38443 10.4029 8.80653 10.4029V10.4019ZM8.80653 3.06391C8.54449 3.06759 8.28414 3.10661 8.03253 3.17991C8.24235 3.46147 8.34391 3.80911 8.31865 4.15935C8.2934 4.50959 8.14301 4.83906 7.89495 5.0876C7.64689 5.33614 7.31772 5.48718 6.96753 5.51312C6.61734 5.53906 6.2695 5.43818 5.98753 5.22891C5.82773 5.82032 5.8574 6.44696 6.07236 7.02063C6.28732 7.5943 6.67675 8.08614 7.18584 8.42691C7.69494 8.76769 8.29807 8.94026 8.91037 8.92034C9.52267 8.90042 10.1133 8.68902 10.5992 8.31587C11.0851 7.94272 11.4417 7.42662 11.6189 6.84018C11.7961 6.25375 11.785 5.62651 11.5871 5.04673C11.3892 4.46695 11.0145 3.96382 10.5157 3.60813C10.0169 3.25244 9.41915 3.06211 8.80653 3.06391Z"
          fill="black"
          className="group-hover:fill-white"
        />
      </svg>
      <p>View reason</p>
    </div>
  );
};

export default ReasonIcon;
