import React from "react";
import styles from "../style.module.css";
import { Avatar } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../../Firebase_config";
import messageFav from "../../../assets/messageFav.png";
import { Pagination } from "antd";
import { formatCustomDate } from "../../../utils/utils";
import { useState } from "react";
const Inbox = ({ inboxArray, total, page, setPage, setPageSize, pageSize }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    console.log("page", page);
    setCurrentPage(page);
    setPage(page);
  };
  const handlePageSizeChange = (current, size) => {
    console.log("page", current, size);
    setPageSize(size);
  };
  async function updateMessageSeen(outboxId, chatId) {
    const outboxChatUserRef = collection(db, "inbox", outboxId, "chatUser");

    try {
      const querySnapshot = await getDocs(outboxChatUserRef);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.id === chatId) {
          const chatDocRef = doc.ref;
          updateDoc(chatDocRef, {
            receiver: true,
          })
            .then(() => {
              console.log("Message updated to msgSeen: true");
            })
            .catch((error) => {
              console.error("Error updating message:", error);
            });
        }
      });
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  }

  if (inboxArray?.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        No data available
      </div>
    );
  }
  function sortMessages(messages) {
    const unseenMessages = messages?.filter((message) => !message.receiver);
    const seenMessages = messages?.filter((message) => message.receiver);

    return [...unseenMessages, ...seenMessages];
  }
  return (
    <div className={styles.tableDiv}>
      <table
        className={`flex auto border-2 border-gray-200 overflow-auto ${styles.table}`}
      >
        <div
          className={`w-full ${styles.tableWrap} overflow-auto md:max-h-[37vw]`}
        >
          {" "}
          <tr className=" w-[160%]  md:w-full flex relative z-10 items-center justify-center md:justify-start">
            <th className="w-[25%] md:w-[25%]">From</th>
            <th className="w-[25%] md:w-[25%]">Subject</th>
            <th className="w-[25%] md:w-[25%]">Message Body</th>
            <th className="w-[25%] md:w-[25%]">Date & Time</th>
          </tr>
          {sortMessages(inboxArray).map((el, i) => {
            return (
              <tr
                key={i}
                onClick={() => {
                  updateMessageSeen(el.to, el.id).then(() => {
                    navigate(
                      `/Messages/Chat/${el.from}?inbox=true&created_by_type=${el.created_by_type}&chatId=${el.id}`
                    );
                  });
                }}
                className={`h-[45px] md:h-auto min-h-[45px] cursor-pointer hover:bg-gray-100 transition-all delay-75 ease-out border-t border-gray-200 flex w-[160%]  md:w-full items-center ${
                  styles.tableTr
                } ${!el.receiver && styles.unseen}`}
              >
                {/* <td className="w-[25%] md:w-[25%] mt-2 truncate">
                  <div className="w-[45px] h-[45px] rounded-full  bg-[#B9DEF1] absolute top-[50%] left-[50%] md:left-[18%] translate-x-[-50%] translate-y-[-50%]">
                    <p className="text-2xl text-[#2C6E8F] font-semibold absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                      N
                    </p>
                  </div>
                </td> */}
                <td className="w-[25%] md:w-[25%]">
                  <img
                    className="w-10 h-10"
                    src={messageFav}
                    alt="messageFav"
                  />
                </td>
                <td className="w-[25%] md:w-[25%] truncate"> {el?.subject}</td>
                <td className="w-[25%] md:w-[25%] truncate">{el?.Msg}</td>
                <td className="w-[25%] md:w-[25%]">
                  {formatCustomDate(el?.timestamp)}
                </td>
              </tr>
            );
          })}
        </div>
      </table>
      {total > 10 && (
        <div className={styles.pagination}>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={total}
            onChange={handlePageChange}
            onShowSizeChange={(current, size) =>
              handlePageSizeChange(current, size)
            }
            // showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default Inbox;
