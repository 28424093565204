import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  InputRightElement,
  Input,
  Button,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { BiSearch } from "react-icons/bi";
import Inbox from "./Inbox/Inbox";
import Outbox from "./Outbox/Outbox";
import inbox from "../../assets/usersDashboard/in.svg";
import outbox from "../../assets/usersDashboard/out.svg";
import { useNavigate } from "react-router-dom";
import {
  collection,
  onSnapshot,
  getDocs,
  limit,
  query,
  startAfter,
} from "firebase/firestore";
import { db } from "../../Firebase_config";
import MessageFilter from "./MessageFilter/MessageFilter";

const Massage = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("Inbox");
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [outboxTotal, setOutboxTotal] = useState([]);
  const [inboxTotal, setInboxTotal] = useState([]);
  const [outboxArray, setOutbox] = useState([]);
  const [inboxArray, setInbox] = useState([]);
  const [chatId, setChatId] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [search, setSearch] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [inboxPage, setInboxPage] = useState(1);
  const [outboxPage, setOutboxPage] = useState(1);
  const [inboxPageSize, setInboxPageSize] = useState(10); // Number of messages per page
  const [outboxPageSize, setOutboxPageSize] = useState(10); // Number of messages per page
  const [lastVisibleDocInbox, setLastVisibleDocInbox] = useState(null);
  const [lastVisibleDocOutbox, setLastVisibleDocOutbox] = useState(null);
  const handleTabsChange = (index) => {
    setTabIndex(index);
    setInboxPage(1);
    setOutboxPage(1);
  };
  const fetchAndSetMessagesTotal = async (collectionRef, setState) => {
    try {
      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const messages = snapshot.docs
          .map((doc) => doc.data())
          .filter((message) => {
            const timestamp = new Date(message.timestamp).getTime();
            const isWithinDateRange =
              (!startDate || timestamp >= new Date(startDate).getTime()) &&
              (!endDate || timestamp <= new Date(endDate).getTime());
            const matchesSearch =
              !search ||
              message.Msg?.toLowerCase().includes(search.toLowerCase()) ||
              message.subject?.toLowerCase().includes(search.toLowerCase());

            return isWithinDateRange && matchesSearch;
          })
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

        setState(messages);
      });

      return unsubscribe;
    } catch (error) {
      console.error("Error fetching messages: ", error);
    }
  };
  const fetchAndSetMessages = async (
    collectionRef,
    setState,
    startDate,
    endDate,
    searchKeyword,
    page,
    pageSize,
    lastVisibleDoc, // Accept the last visible document as a parameter
    setLastVisibleDoc // Adding a parameter to track the last visible document
  ) => {
    try {
      let queryRef = query(collectionRef, limit(pageSize));

      if (page > 1 && lastVisibleDoc) {
        queryRef = query(
          collectionRef,
          startAfter(lastVisibleDoc),
          limit(pageSize)
        );
      }

      const snapshot = await getDocs(queryRef);
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      setLastVisibleDoc(lastVisible);

      const messages = snapshot.docs
        .map((doc) => doc.data())
        .filter((message) => {
          const timestamp = new Date(message.timestamp).getTime();
          const isWithinDateRange =
            (!startDate || timestamp >= startDate) &&
            (!endDate || timestamp <= endDate);
          const matchesSearch =
            !searchKeyword ||
            ((message.Msg?.toLowerCase()?.includes(
              searchKeyword.toLowerCase()
            ) ||
              message.subject
                ?.toLowerCase()
                ?.includes(searchKeyword.toLowerCase())) ??
              false);
          return isWithinDateRange && matchesSearch;
        });

      const sortedMessages = messages.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      );

      setState(sortedMessages);
    } catch (error) {
      console.error("Error fetching messages: ", error);
    }
  };
  const fetchMessages = async (
    collectionName,
    userId,
    setState,
    startDate,
    endDate,
    searchKeyword,
    page,
    pageSize,
    lastVisibleDoc, // Accept the last visible document as a parameter
    setLastVisibleDoc // Pass the function to update last visible document
  ) => {
    const collectionRef = collection(db, collectionName, userId, "chatUser");
    await fetchAndSetMessages(
      collectionRef,
      setState,
      startDate,
      endDate,
      searchKeyword,
      page,
      pageSize,
      lastVisibleDoc,
      setLastVisibleDoc
    );
  };

  // Use separate states for inbox and outbox pagination
  useEffect(() => {
    generateChatID();
    fetchMessages(
      "outbox",
      userId,
      setOutbox,
      startDate,
      endDate,
      search,
      outboxPage,
      outboxPageSize,
      lastVisibleDocOutbox,
      setLastVisibleDocOutbox
    );
    fetchMessages(
      "inbox",
      userId,
      setInbox,
      startDate,
      endDate,
      search,
      inboxPage,
      inboxPageSize,
      lastVisibleDocInbox,
      setLastVisibleDocInbox
    );
  }, [filterApplied, search, inboxPage, outboxPage, startDate, endDate]);

  const fetchMessagesTotal = async (collectionName, userId, setState) => {
    const collectionRef = collection(db, collectionName, userId, "chatUser");
    const unsubscribe = await fetchAndSetMessagesTotal(collectionRef, setState);
    return unsubscribe;
  };

  useEffect(() => {
    const initializeMessages = async () => {
      generateChatID();
      const unsubscribeOutbox = await fetchMessagesTotal(
        "outbox",
        userId,
        setOutboxTotal,
        setOutbox
      );
      const unsubscribeInbox = await fetchMessagesTotal(
        "inbox",
        userId,
        setInboxTotal,
        setInbox
      );

      return () => {
        unsubscribeOutbox && unsubscribeOutbox();
        unsubscribeInbox && unsubscribeInbox();
      };
    };

    initializeMessages();
  }, [filterApplied, search, startDate, endDate]);

  // Usage in your component
  // useEffect(() => {
  //   fetchMessagesTotla(
  //     "outbox",
  //     userId,
  //     setOutboxTotal,
  //     startDate,
  //     endDate,
  //     search
  //   );
  //   fetchMessagesTotla(
  //     "inbox",
  //     userId,
  //     setInboxTotal,
  //     startDate,
  //     endDate,
  //     search
  //   );
  // }, [filterApplied, search]);

  function generateChatID() {
    const timestamp = new Date().getTime(); // Current timestamp in milliseconds
    const randomString = Math.random().toString(36).substr(2, 10); // Random string of length 10

    setChatId(`${timestamp}${randomString}`);
  }

  function getInboxCount(messages) {
    const unseenMessages = messages.filter((message) => message.receiver);

    return messages.length;
  }
  function getOutboxCount(messages) {
    const unseenMessages = messages.filter((message) => message.sender);

    return messages.length;
  }
  const totalOutBox = getOutboxCount(outboxTotal);
  const totalInBox = getInboxCount(inboxTotal);
  return (
    <div className={styles.MainContainer}>
      <Helmet>
        <title> Messages - NETME</title>
      </Helmet>
      <div className={styles.firstDiv}>
        <h1 className="hidden text-3xl font-bold md:block md:pt-5 lg:pt-0">
          Messages
        </h1>
        <div className={styles.filterDiv}>
          <div className="hidden md:block">
            <InputGroup w={200}>
              <Input
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
              <InputRightElement>
                <BiSearch color="green.500" />
              </InputRightElement>
            </InputGroup>
          </div>
          <MessageFilter
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setFilterApplied={setFilterApplied}
            filterApplied={filterApplied}
          />
          <div className="hidden md:block">
            <Button
              bg="#8CC9E9"
              onClick={() => navigate(`/CreateMessages?chatId=${chatId}`)}
            >
              Create New Message
            </Button>
          </div>
        </div>
      </div>
      <div className="px-5 md:px-0">
        <h1 className="pt-28 text-3xl font-bold md:hidden">Messages</h1>
        <div className="flex flex-col sm:flex-row md:hidden my-5 gap-4 sm:gap-10">
          <InputGroup w={200}>
            <Input
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <InputRightElement>
              <BiSearch color="green.500" />
            </InputRightElement>
          </InputGroup>
          <div className="block md:hidden">
            <Button
              bg="#8CC9E9"
              onClick={() => navigate(`/CreateMessages?chatId=${chatId}`)}
              width={200}
            >
              Create New Message
            </Button>
          </div>
        </div>
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            <Tab
              className={`${styles.tabPanel}`}
              onClick={() => setType("Inbox")}
            >
              {" "}
              <img src={inbox} alt="" /> &nbsp; Inbox{" "}
              <span
                id={type === "Inbox" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {getInboxCount(inboxTotal)}
              </span>
            </Tab>
            <span className="hidden md:block">
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
            <span className="block md:hidden">&nbsp; &nbsp;</span>
            <Tab
              className={`${styles.tabPanel}`}
              onClick={() => setType("Outbox")}
            >
              {" "}
              <img src={outbox} alt="" /> &nbsp; Outbox{" "}
              <span
                id={type === "Outbox" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {getOutboxCount(outboxTotal)}
              </span>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Inbox
                inboxArray={inboxArray}
                page={inboxPage}
                setPage={setInboxPage}
                total={totalInBox}
                setPageSize={setInboxPageSize}
                pageSize={inboxPageSize}
              />
            </TabPanel>
            <TabPanel>
              <Outbox
                outboxArray={outboxArray}
                page={outboxPage}
                setPage={setOutboxPage}
                total={totalOutBox}
                setPageSize={setOutboxPageSize}
                pageSize={outboxPageSize}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default Massage;
